import React from 'react';

import Heading from '../../components/heading';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Nav from '../../components/nav';
import theme from '../../theme/theme';

const ContactMessageSent = () => {
  return (
    <Layout>
      <SEO title="Message Sent" />
      <Nav />
      <main
        css={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          justifyContent: 'center',
          padding: '16px 0',
          textAlign: 'center',
        }}
      >
        <Heading level="1" size="900" css={{ marginBottom: 16 }}>
          Error
        </Heading>
        <p css={{ ...theme.text['600'], opacity: '0.7' }}>
          Your message could not be sent. Please email me directly at{' '}
          <a css={{ color: theme.colors.bright }} href="mailto:tyler@madebytr.com">tyler@madebytr.com</a>
        </p>
      </main>
    </Layout>
  );
};

export default ContactMessageSent;
